import Component from 'can-component';
import DefineMap from 'can-define/map/map';
import './guidelines.less';
import { darkModeStyles } from '~/components/map/map';
import loader from '@loader';
import view from './guidelines.stache';

export const ViewModel = DefineMap.extend({
	description: {
		default: 'Learn how to plan and lay a great trail for LBH3.'
	},
	get googleMapsKey() {
		return loader.googleMapsKey;
	},
	get ogTitle() {
		return 'Haring Guidelines';
	},
	get title() {
		return `${this.ogTitle} | LBH3`;
	},

	connectedCallback(element) {
		if (loader.isNode) {
			return;
		}

		let map;
		const boxMap = document.getElementById('boxMap');
		const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

		const mediaQueryListener = () => {
			const isDarkMode = mediaQueryList.matches;

			// If there’s already a map…
			if (map) {
				// Just change the styles
				map.setOptions({
					styles: isDarkMode ? darkModeStyles : []
				});
			} else {

				// Remove all children (useful for switching from the HTML embed to the JS map)
				while (boxMap.firstChild) {
					boxMap.removeChild(boxMap.firstChild);
				}

				const onloadHandler = () => {

					// Define the coordinates for the outer path
					const outerCoordinates = [
						{lat: 90, lng: -179}, // north west
						{lat: -90, lng: -179}, // south west
						{lat: -90, lng: 0}, // south east
						{lat: 90, lng: 0}  // north east
					];

					// Define the coordinates for the inner path
					const innerCoordinates = [
						{lat: 33.6332086653, lng: -117.966498929},
						{lat: 33.6354239794, lng: -117.963945466},
						{lat: 33.63539, lng: -117.96389},
						{lat: 33.63743, lng: -117.96206},
						{lat: 33.63946, lng: -117.9602},
						{lat: 33.64283, lng: -117.95706},
						{lat: 33.64351, lng: -117.95644},
						{lat: 33.64471, lng: -117.95535},
						{lat: 33.64525, lng: -117.95495},
						{lat: 33.64592, lng: -117.95452},
						{lat: 33.64622, lng: -117.95435},
						{lat: 33.64696, lng: -117.954},
						{lat: 33.64789, lng: -117.95371},
						{lat: 33.64869, lng: -117.95355},
						{lat: 33.64929, lng: -117.95349},
						{lat: 33.65053, lng: -117.95348},
						{lat: 33.65254, lng: -117.95352},
						{lat: 33.65652, lng: -117.95358},
						{lat: 33.65783, lng: -117.9536},
						{lat: 33.65973, lng: -117.95361},
						{lat: 33.66406, lng: -117.95367},
						{lat: 33.66621, lng: -117.9537},
						{lat: 33.66915, lng: -117.95373},
						{lat: 33.67157, lng: -117.95378},
						{lat: 33.67476, lng: -117.95381},
						{lat: 33.67875, lng: -117.95388},
						{lat: 33.68233, lng: -117.95393},
						{lat: 33.68603, lng: -117.95398},
						{lat: 33.68877, lng: -117.95403},
						{lat: 33.69082, lng: -117.95405},
						{lat: 33.692, lng: -117.95409},
						{lat: 33.69295, lng: -117.95409},
						{lat: 33.69356, lng: -117.95412},
						{lat: 33.69432, lng: -117.95411},
						{lat: 33.69539, lng: -117.95414},
						{lat: 33.69626, lng: -117.95416},
						{lat: 33.69686, lng: -117.95418},
						{lat: 33.6973, lng: -117.95417},
						{lat: 33.69784, lng: -117.95418},
						{lat: 33.69881, lng: -117.9542},
						{lat: 33.70169, lng: -117.95424},
						{lat: 33.70323, lng: -117.95425},
						{lat: 33.70445, lng: -117.9543},
						{lat: 33.70618, lng: -117.95432},
						{lat: 33.70841, lng: -117.95432},
						{lat: 33.70994, lng: -117.95433},
						{lat: 33.71217, lng: -117.95436},
						{lat: 33.71579, lng: -117.9544},
						{lat: 33.71669, lng: -117.95441},
						{lat: 33.71763, lng: -117.95441},
						{lat: 33.71857, lng: -117.95444},
						{lat: 33.72024, lng: -117.95446},
						{lat: 33.72209, lng: -117.95447},
						{lat: 33.72607, lng: -117.95451},
						{lat: 33.7276, lng: -117.95453},
						{lat: 33.72865, lng: -117.95455},
						{lat: 33.72931, lng: -117.95454},
						{lat: 33.73035, lng: -117.95454},
						{lat: 33.73069, lng: -117.95455},
						{lat: 33.7324, lng: -117.9546},
						{lat: 33.7337, lng: -117.95461},
						{lat: 33.73517, lng: -117.95461},
						{lat: 33.73527, lng: -117.95471},
						{lat: 33.73537, lng: -117.95471},
						{lat: 33.73605, lng: -117.95473},
						{lat: 33.73674, lng: -117.95475},
						{lat: 33.73678, lng: -117.95469},
						{lat: 33.73685, lng: -117.95465},
						{lat: 33.73723, lng: -117.95465},
						{lat: 33.73778, lng: -117.95465},
						{lat: 33.73838, lng: -117.95467},
						{lat: 33.73847, lng: -117.95477},
						{lat: 33.73936, lng: -117.95479},
						{lat: 33.73989, lng: -117.95479},
						{lat: 33.74083, lng: -117.95481},
						{lat: 33.74093, lng: -117.95489},
						{lat: 33.74132, lng: -117.95489},
						{lat: 33.74289, lng: -117.95492},
						{lat: 33.74334, lng: -117.95493},
						{lat: 33.74338, lng: -117.95492},
						{lat: 33.7435, lng: -117.95484},
						{lat: 33.74392, lng: -117.95485},
						{lat: 33.74402, lng: -117.95477},
						{lat: 33.74421, lng: -117.95475},
						{lat: 33.74457, lng: -117.95475},
						{lat: 33.74501, lng: -117.95476},
						{lat: 33.74766, lng: -117.95479},
						{lat: 33.74908, lng: -117.9548},
						{lat: 33.75024, lng: -117.95481},
						{lat: 33.75034, lng: -117.95489},
						{lat: 33.75045, lng: -117.95489},
						{lat: 33.75099, lng: -117.95489},
						{lat: 33.75121, lng: -117.95489},
						{lat: 33.75128, lng: -117.95482},
						{lat: 33.75129, lng: -117.95481},
						{lat: 33.75223, lng: -117.95481},
						{lat: 33.75359, lng: -117.95482},
						{lat: 33.75493, lng: -117.95482},
						{lat: 33.75585, lng: -117.95484},
						{lat: 33.7573, lng: -117.95484},
						{lat: 33.75818, lng: -117.95484},
						{lat: 33.75834, lng: -117.95485},
						{lat: 33.75894, lng: -117.95486},
						{lat: 33.75948, lng: -117.95483},
						{lat: 33.76059, lng: -117.95484},
						{lat: 33.76192, lng: -117.95487},
						{lat: 33.76482, lng: -117.9549},
						{lat: 33.7683, lng: -117.95492},
						{lat: 33.7691, lng: -117.95493},
						{lat: 33.77134, lng: -117.95492},
						{lat: 33.7728, lng: -117.95495},
						{lat: 33.77313, lng: -117.95494},
						{lat: 33.77348, lng: -117.95495},
						{lat: 33.77412, lng: -117.95497},
						{lat: 33.77449, lng: -117.95506},
						{lat: 33.77467, lng: -117.95512},
						{lat: 33.77478, lng: -117.95519},
						{lat: 33.77517, lng: -117.95547},
						{lat: 33.77719, lng: -117.9571},
						{lat: 33.77806, lng: -117.95779},
						{lat: 33.77829, lng: -117.95794},
						{lat: 33.77859, lng: -117.95811},
						{lat: 33.77888, lng: -117.95824},
						{lat: 33.77931, lng: -117.95837},
						{lat: 33.77954, lng: -117.95841},
						{lat: 33.7798, lng: -117.95844},
						{lat: 33.78045, lng: -117.95845},
						{lat: 33.7813, lng: -117.95848},
						{lat: 33.78267, lng: -117.9585},
						{lat: 33.78437, lng: -117.95854},
						{lat: 33.78556, lng: -117.95859},
						{lat: 33.78635, lng: -117.9586},
						{lat: 33.78793, lng: -117.95863},
						{lat: 33.78867, lng: -117.95863},
						{lat: 33.7896, lng: -117.95865},
						{lat: 33.79079, lng: -117.95859},
						{lat: 33.79296, lng: -117.95859},
						{lat: 33.79511, lng: -117.95862},
						{lat: 33.79757, lng: -117.95866},
						{lat: 33.79945, lng: -117.95867},
						{lat: 33.80103, lng: -117.95869},
						{lat: 33.80156, lng: -117.95876},
						{lat: 33.80196, lng: -117.95878},
						{lat: 33.80306, lng: -117.95878},
						{lat: 33.80407, lng: -117.95881},
						{lat: 33.80536, lng: -117.9588},
						{lat: 33.80658, lng: -117.95876},
						{lat: 33.80797, lng: -117.95875},
						{lat: 33.80959, lng: -117.95876},
						{lat: 33.81133, lng: -117.95877},
						{lat: 33.81203, lng: -117.95882},
						{lat: 33.81528, lng: -117.95885},
						{lat: 33.81675, lng: -117.95888},
						{lat: 33.81736, lng: -117.95889},
						{lat: 33.81837, lng: -117.95886},
						{lat: 33.81904, lng: -117.95887},
						{lat: 33.81974, lng: -117.95888},
						{lat: 33.82027, lng: -117.95887},
						{lat: 33.82038, lng: -117.95893},
						{lat: 33.82077, lng: -117.95894},
						{lat: 33.82111, lng: -117.95894},
						{lat: 33.82175, lng: -117.95894},
						{lat: 33.8223, lng: -117.95894},
						{lat: 33.82265, lng: -117.959},
						{lat: 33.82338, lng: -117.95902},
						{lat: 33.82486, lng: -117.95902},
						{lat: 33.82544, lng: -117.95903},
						{lat: 33.82584, lng: -117.95905},
						{lat: 33.82697, lng: -117.95905},
						{lat: 33.82836, lng: -117.95904},
						{lat: 33.82889, lng: -117.95903},
						{lat: 33.82974, lng: -117.95905},
						{lat: 33.82992, lng: -117.95897},
						{lat: 33.83046, lng: -117.95896},
						{lat: 33.83075, lng: -117.95897},
						{lat: 33.83125, lng: -117.95899},
						{lat: 33.8315, lng: -117.95897},
						{lat: 33.83163, lng: -117.95904},
						{lat: 33.83191, lng: -117.95906},
						{lat: 33.83259, lng: -117.95906},
						{lat: 33.83319, lng: -117.95905},
						{lat: 33.83341, lng: -117.95908},
						{lat: 33.83553, lng: -117.95908},
						{lat: 33.83601, lng: -117.9591},
						{lat: 33.83698, lng: -117.9591},
						{lat: 33.83715, lng: -117.95902},
						{lat: 33.83775, lng: -117.95905},
						{lat: 33.8379, lng: -117.95905},
						{lat: 33.83857, lng: -117.95906},
						{lat: 33.8389, lng: -117.95906},
						{lat: 33.83916, lng: -117.95906},
						{lat: 33.83931, lng: -117.95913},
						{lat: 33.8402, lng: -117.95915},
						{lat: 33.84114, lng: -117.95912},
						{lat: 33.84258, lng: -117.95917},
						{lat: 33.84321, lng: -117.95906},
						{lat: 33.84372, lng: -117.95896},
						{lat: 33.84397, lng: -117.95894},
						{lat: 33.84469, lng: -117.95904},
						{lat: 33.84544, lng: -117.95913},
						{lat: 33.84596, lng: -117.95918},
						{lat: 33.84687, lng: -117.95925},
						{lat: 33.84713, lng: -117.95927},
						{lat: 33.84744, lng: -117.95926},
						{lat: 33.84817, lng: -117.95926},
						{lat: 33.84915, lng: -117.95925},
						{lat: 33.84971, lng: -117.95924},
						{lat: 33.85181, lng: -117.95927},
						{lat: 33.85257, lng: -117.95933},
						{lat: 33.85305, lng: -117.95933},
						{lat: 33.8536, lng: -117.95931},
						{lat: 33.85382, lng: -117.95932},
						{lat: 33.85402, lng: -117.95932},
						{lat: 33.85461, lng: -117.95932},
						{lat: 33.85512, lng: -117.95931},
						{lat: 33.85526, lng: -117.95925},
						{lat: 33.85618, lng: -117.95925},
						{lat: 33.8573, lng: -117.95926},
						{lat: 33.85832, lng: -117.95929},
						{lat: 33.85921, lng: -117.95929},
						{lat: 33.86161, lng: -117.95933},
						{lat: 33.86652, lng: -117.95938},
						{lat: 33.86729, lng: -117.95941},
						{lat: 33.86847, lng: -117.95941},
						{lat: 33.86933, lng: -117.9594},
						{lat: 33.87011, lng: -117.95943},
						{lat: 33.87011, lng: -117.95943},
						{lat: 33.87017, lng: -117.95943},
						{lat: 33.87017, lng: -117.95951},
						{lat: 33.87011, lng: -117.95956},
						{lat: 33.87011, lng: -117.95979},
						{lat: 33.87011, lng: -117.96026},
						{lat: 33.87011, lng: -117.96077},
						{lat: 33.87007, lng: -117.96733},
						{lat: 33.87006, lng: -117.96823},
						{lat: 33.87006, lng: -117.96823},
						{lat: 33.87053, lng: -117.9682},
						{lat: 33.87086, lng: -117.96821},
						{lat: 33.87208, lng: -117.96821},
						{lat: 33.87256, lng: -117.96819},
						{lat: 33.87308, lng: -117.96826},
						{lat: 33.87348, lng: -117.96831},
						{lat: 33.8737, lng: -117.96834},
						{lat: 33.87422, lng: -117.96833},
						{lat: 33.87511, lng: -117.96831},
						{lat: 33.87533, lng: -117.9683},
						{lat: 33.87586, lng: -117.96826},
						{lat: 33.87654, lng: -117.96802},
						{lat: 33.87679, lng: -117.96789},
						{lat: 33.87769, lng: -117.96738},
						{lat: 33.87785, lng: -117.96729},
						{lat: 33.87937, lng: -117.96641},
						{lat: 33.87994, lng: -117.96619},
						{lat: 33.88022, lng: -117.96613},
						{lat: 33.88055, lng: -117.96611},
						{lat: 33.88085, lng: -117.96612},
						{lat: 33.88146, lng: -117.96618},
						{lat: 33.88187, lng: -117.96621},
						{lat: 33.88214, lng: -117.96616},
						{lat: 33.88235, lng: -117.96611},
						{lat: 33.88259, lng: -117.966},
						{lat: 33.88299, lng: -117.96581},
						{lat: 33.88365, lng: -117.96552},
						{lat: 33.884, lng: -117.96544},
						{lat: 33.88432, lng: -117.96542},
						{lat: 33.88542, lng: -117.96555},
						{lat: 33.88591, lng: -117.96563},
						{lat: 33.88722, lng: -117.96582},
						{lat: 33.88782, lng: -117.96596},
						{lat: 33.88824, lng: -117.96608},
						{lat: 33.88874, lng: -117.96622},
						{lat: 33.88895, lng: -117.96626},
						{lat: 33.88934, lng: -117.96631},
						{lat: 33.8898, lng: -117.96629},
						{lat: 33.88998, lng: -117.96625},
						{lat: 33.89015, lng: -117.9662},
						{lat: 33.89066, lng: -117.96601},
						{lat: 33.89235, lng: -117.96545},
						{lat: 33.89303, lng: -117.96525},
						{lat: 33.89327, lng: -117.9652},
						{lat: 33.89348, lng: -117.96517},
						{lat: 33.8939, lng: -117.96515},
						{lat: 33.89434, lng: -117.96517},
						{lat: 33.89444, lng: -117.96519},
						{lat: 33.89517, lng: -117.96548},
						{lat: 33.89561, lng: -117.96567},
						{lat: 33.89593, lng: -117.96581},
						{lat: 33.89603, lng: -117.96589},
						{lat: 33.89631, lng: -117.96588},
						{lat: 33.89654, lng: -117.96587},
						{lat: 33.89711, lng: -117.96579},
						{lat: 33.89745, lng: -117.96573},
						{lat: 33.89771, lng: -117.96564},
						{lat: 33.89822, lng: -117.96536},
						{lat: 33.89851, lng: -117.96509},
						{lat: 33.89876, lng: -117.96482},
						{lat: 33.89891, lng: -117.96463},
						{lat: 33.89904, lng: -117.96442},
						{lat: 33.89917, lng: -117.96412},
						{lat: 33.89936, lng: -117.96353},
						{lat: 33.8994, lng: -117.96321},
						{lat: 33.89941, lng: -117.96249},
						{lat: 33.89939, lng: -117.96231},
						{lat: 33.89929, lng: -117.96188},
						{lat: 33.89898, lng: -117.96055},
						{lat: 33.8989, lng: -117.96006},
						{lat: 33.89889, lng: -117.95963},
						{lat: 33.89893, lng: -117.95917},
						{lat: 33.899, lng: -117.95884},
						{lat: 33.89912, lng: -117.95843},
						{lat: 33.89933, lng: -117.958},
						{lat: 33.89957, lng: -117.95764},
						{lat: 33.89982, lng: -117.95733},
						{lat: 33.90011, lng: -117.95705},
						{lat: 33.9003, lng: -117.95691},
						{lat: 33.90057, lng: -117.95676},
						{lat: 33.90077, lng: -117.95668},
						{lat: 33.90099, lng: -117.95661},
						{lat: 33.90117, lng: -117.95657},
						{lat: 33.90163, lng: -117.95653},
						{lat: 33.90191, lng: -117.95649},
						{lat: 33.90234, lng: -117.95644},
						{lat: 33.90274, lng: -117.95643},
						{lat: 33.90337, lng: -117.95645},
						{lat: 33.90424, lng: -117.95652},
						{lat: 33.90478, lng: -117.95662},
						{lat: 33.90507, lng: -117.95669},
						{lat: 33.90531, lng: -117.95679},
						{lat: 33.90573, lng: -117.95699},
						{lat: 33.90596, lng: -117.95713},
						{lat: 33.90618, lng: -117.95733},
						{lat: 33.90686, lng: -117.95788},
						{lat: 33.9071, lng: -117.95805},
						{lat: 33.90755, lng: -117.9583},
						{lat: 33.90812, lng: -117.95854},
						{lat: 33.90847, lng: -117.95866},
						{lat: 33.90894, lng: -117.95874},
						{lat: 33.90935, lng: -117.9588},
						{lat: 33.91006, lng: -117.95887},
						{lat: 33.91086, lng: -117.959},
						{lat: 33.91143, lng: -117.95909},
						{lat: 33.91215, lng: -117.9592},
						{lat: 33.91253, lng: -117.95924},
						{lat: 33.91317, lng: -117.95922},
						{lat: 33.91328, lng: -117.95922},
						{lat: 33.91334, lng: -117.95915},
						{lat: 33.9136, lng: -117.95916},
						{lat: 33.91472, lng: -117.95916},
						{lat: 33.9163, lng: -117.95915},
						{lat: 33.9173, lng: -117.95917},
						{lat: 33.9173, lng: -117.95917},
						{lat: 33.91739, lng: -117.95918},
						{lat: 33.91739, lng: -117.95982},
						{lat: 33.91739, lng: -117.96083},
						{lat: 33.91738, lng: -117.96233},
						{lat: 33.91741, lng: -117.96639},
						{lat: 33.91743, lng: -117.96794},
						{lat: 33.91742, lng: -117.96948},
						{lat: 33.91741, lng: -117.97118},
						{lat: 33.91742, lng: -117.97433},
						{lat: 33.91742, lng: -117.97602},
						{lat: 33.91743, lng: -117.97676},
						{lat: 33.91747, lng: -117.97686},
						{lat: 33.91747, lng: -117.9781},
						{lat: 33.91746, lng: -117.97899},
						{lat: 33.91745, lng: -117.98014},
						{lat: 33.91744, lng: -117.98206},
						{lat: 33.91741, lng: -117.98576},
						{lat: 33.91742, lng: -117.98876},
						{lat: 33.9174, lng: -117.99051},
						{lat: 33.91739, lng: -117.99141},
						{lat: 33.91739, lng: -117.99197},
						{lat: 33.91737, lng: -117.99201},
						{lat: 33.91734, lng: -117.99216},
						{lat: 33.91734, lng: -117.99267},
						{lat: 33.91734, lng: -117.99284},
						{lat: 33.91738, lng: -117.99296},
						{lat: 33.91739, lng: -117.99326},
						{lat: 33.91739, lng: -117.99374},
						{lat: 33.91737, lng: -117.99536},
						{lat: 33.91738, lng: -117.9965},
						{lat: 33.91736, lng: -117.99947},
						{lat: 33.91735, lng: -118.00076},
						{lat: 33.91731, lng: -118.00112},
						{lat: 33.91724, lng: -118.00147},
						{lat: 33.91673, lng: -118.00314},
						{lat: 33.91646, lng: -118.00401},
						{lat: 33.91631, lng: -118.00454},
						{lat: 33.91623, lng: -118.00493},
						{lat: 33.91621, lng: -118.00534},
						{lat: 33.91624, lng: -118.00589},
						{lat: 33.9163, lng: -118.00625},
						{lat: 33.91641, lng: -118.00662},
						{lat: 33.91699, lng: -118.00813},
						{lat: 33.91712, lng: -118.00848},
						{lat: 33.91721, lng: -118.00881},
						{lat: 33.91729, lng: -118.00931},
						{lat: 33.9173, lng: -118.00966},
						{lat: 33.9173, lng: -118.01087},
						{lat: 33.91729, lng: -118.01184},
						{lat: 33.91728, lng: -118.0134},
						{lat: 33.91723, lng: -118.01359},
						{lat: 33.91714, lng: -118.01359},
						{lat: 33.91714, lng: -118.01379},
						{lat: 33.91713, lng: -118.01404},
						{lat: 33.91713, lng: -118.01463},
						{lat: 33.91713, lng: -118.01623},
						{lat: 33.91711, lng: -118.01875},
						{lat: 33.91709, lng: -118.02202},
						{lat: 33.91707, lng: -118.0264},
						{lat: 33.91707, lng: -118.02712},
						{lat: 33.91701, lng: -118.02729},
						{lat: 33.917, lng: -118.02828},
						{lat: 33.91699, lng: -118.03038},
						{lat: 33.91697, lng: -118.03387},
						{lat: 33.91696, lng: -118.03472},
						{lat: 33.91696, lng: -118.03527},
						{lat: 33.91702, lng: -118.03534},
						{lat: 33.91702, lng: -118.03616},
						{lat: 33.91695, lng: -118.03626},
						{lat: 33.91696, lng: -118.03669},
						{lat: 33.91696, lng: -118.03696},
						{lat: 33.91695, lng: -118.03747},
						{lat: 33.91694, lng: -118.03865},
						{lat: 33.91693, lng: -118.04068},
						{lat: 33.91692, lng: -118.04277},
						{lat: 33.91689, lng: -118.04769},
						{lat: 33.91684, lng: -118.05024},
						{lat: 33.91686, lng: -118.05383},
						{lat: 33.91689, lng: -118.05436},
						{lat: 33.91698, lng: -118.05518},
						{lat: 33.91707, lng: -118.05544},
						{lat: 33.91712, lng: -118.05589},
						{lat: 33.91717, lng: -118.05662},
						{lat: 33.91716, lng: -118.05869},
						{lat: 33.91715, lng: -118.05915},
						{lat: 33.91709, lng: -118.05933},
						{lat: 33.91709, lng: -118.05947},
						{lat: 33.91708, lng: -118.05985},
						{lat: 33.91707, lng: -118.06042},
						{lat: 33.91706, lng: -118.06158},
						{lat: 33.91706, lng: -118.06202},
						{lat: 33.91713, lng: -118.06217},
						{lat: 33.91712, lng: -118.06323},
						{lat: 33.91712, lng: -118.06366},
						{lat: 33.91721, lng: -118.06366},
						{lat: 33.91726, lng: -118.06381},
						{lat: 33.91726, lng: -118.06401},
						{lat: 33.91726, lng: -118.06515},
						{lat: 33.91725, lng: -118.06638},
						{lat: 33.91724, lng: -118.06864},
						{lat: 33.91723, lng: -118.07248},
						{lat: 33.91722, lng: -118.07781},
						{lat: 33.91726, lng: -118.0783},
						{lat: 33.91729, lng: -118.07943},
						{lat: 33.91729, lng: -118.0798},
						{lat: 33.9173, lng: -118.08037},
						{lat: 33.91721, lng: -118.08134},
						{lat: 33.91721, lng: -118.08195},
						{lat: 33.9172, lng: -118.08298},
						{lat: 33.91719, lng: -118.08321},
						{lat: 33.91715, lng: -118.08331},
						{lat: 33.91715, lng: -118.08348},
						{lat: 33.91715, lng: -118.08379},
						{lat: 33.91715, lng: -118.08393},
						{lat: 33.91706, lng: -118.08393},
						{lat: 33.91705, lng: -118.08435},
						{lat: 33.91705, lng: -118.08461},
						{lat: 33.91705, lng: -118.08599},
						{lat: 33.91707, lng: -118.08879},
						{lat: 33.917, lng: -118.0889},
						{lat: 33.917, lng: -118.08892},
						{lat: 33.917, lng: -118.08932},
						{lat: 33.917, lng: -118.08962},
						{lat: 33.91708, lng: -118.08978},
						{lat: 33.9172, lng: -118.08999},
						{lat: 33.91721, lng: -118.09024},
						{lat: 33.9172, lng: -118.09109},
						{lat: 33.91721, lng: -118.0943},
						{lat: 33.91721, lng: -118.09484},
						{lat: 33.91718, lng: -118.09491},
						{lat: 33.91714, lng: -118.09499},
						{lat: 33.91714, lng: -118.09541},
						{lat: 33.91714, lng: -118.09599},
						{lat: 33.91714, lng: -118.09682},
						{lat: 33.91714, lng: -118.09788},
						{lat: 33.91719, lng: -118.09801},
						{lat: 33.9172, lng: -118.09856},
						{lat: 33.9172, lng: -118.09935},
						{lat: 33.9172, lng: -118.10191},
						{lat: 33.91721, lng: -118.10472},
						{lat: 33.91721, lng: -118.10472},
						{lat: 33.9139550388, lng: -118.104802016},
						{lat: 33.9139550388, lng: -118.104802016},
						{lat: 33.913972846, lng: -118.112827186},
						{lat: 33.91396, lng: -118.11283},
						{lat: 33.91397, lng: -118.11331},
						{lat: 33.91403, lng: -118.11395},
						{lat: 33.91397, lng: -118.11568},
						{lat: 33.91392, lng: -118.11696},
						{lat: 33.91384, lng: -118.11824},
						{lat: 33.91364, lng: -118.12087},
						{lat: 33.91347, lng: -118.12312},
						{lat: 33.91339, lng: -118.12445},
						{lat: 33.91334, lng: -118.12556},
						{lat: 33.91326, lng: -118.12774},
						{lat: 33.91324, lng: -118.14078},
						{lat: 33.91324, lng: -118.14206},
						{lat: 33.91322, lng: -118.14362},
						{lat: 33.91319, lng: -118.14499},
						{lat: 33.91311, lng: -118.14692},
						{lat: 33.91294, lng: -118.14994},
						{lat: 33.91278, lng: -118.15177},
						{lat: 33.91258, lng: -118.15391},
						{lat: 33.91239, lng: -118.15595},
						{lat: 33.91224, lng: -118.15811},
						{lat: 33.91208, lng: -118.15967},
						{lat: 33.91203, lng: -118.16048},
						{lat: 33.91175, lng: -118.16383},
						{lat: 33.91161, lng: -118.16528},
						{lat: 33.91134, lng: -118.16814},
						{lat: 33.91125, lng: -118.16992},
						{lat: 33.91123, lng: -118.17117},
						{lat: 33.91127, lng: -118.17277},
						{lat: 33.91135, lng: -118.17397},
						{lat: 33.91141, lng: -118.1745},
						{lat: 33.91156, lng: -118.17539},
						{lat: 33.9118, lng: -118.17642},
						{lat: 33.91189, lng: -118.17666},
						{lat: 33.9121, lng: -118.17729},
						{lat: 33.91233, lng: -118.17793},
						{lat: 33.91308, lng: -118.18013},
						{lat: 33.91352, lng: -118.18147},
						{lat: 33.91403, lng: -118.18308},
						{lat: 33.91441, lng: -118.18421},
						{lat: 33.91469, lng: -118.18498},
						{lat: 33.91553, lng: -118.18755},
						{lat: 33.91589, lng: -118.18862},
						{lat: 33.9162, lng: -118.18943},
						{lat: 33.91657, lng: -118.19041},
						{lat: 33.91694, lng: -118.19136},
						{lat: 33.91733, lng: -118.19229},
						{lat: 33.91825, lng: -118.19442},
						{lat: 33.9198, lng: -118.19797},
						{lat: 33.92143, lng: -118.20166},
						{lat: 33.92212, lng: -118.20326},
						{lat: 33.92262, lng: -118.20439},
						{lat: 33.92322, lng: -118.20574},
						{lat: 33.92344, lng: -118.20628},
						{lat: 33.92451, lng: -118.20866},
						{lat: 33.92513, lng: -118.21009},
						{lat: 33.9255, lng: -118.21092},
						{lat: 33.92595, lng: -118.2119},
						{lat: 33.92707, lng: -118.21443},
						{lat: 33.92822, lng: -118.21712},
						{lat: 33.92885, lng: -118.21856},
						{lat: 33.92918, lng: -118.21949},
						{lat: 33.92934, lng: -118.22005},
						{lat: 33.92957, lng: -118.22105},
						{lat: 33.92969, lng: -118.22174},
						{lat: 33.92978, lng: -118.2225},
						{lat: 33.92982, lng: -118.22342},
						{lat: 33.92979, lng: -118.22454},
						{lat: 33.92969, lng: -118.22556},
						{lat: 33.92964, lng: -118.22589},
						{lat: 33.92941, lng: -118.22697},
						{lat: 33.92858, lng: -118.23021},
						{lat: 33.92842, lng: -118.23091},
						{lat: 33.92829, lng: -118.23173},
						{lat: 33.92825, lng: -118.23211},
						{lat: 33.9282, lng: -118.2327},
						{lat: 33.92819, lng: -118.23369},
						{lat: 33.92819, lng: -118.23405},
						{lat: 33.92832, lng: -118.23605},
						{lat: 33.92841, lng: -118.23734},
						{lat: 33.92848, lng: -118.23875},
						{lat: 33.92855, lng: -118.23987},
						{lat: 33.92873, lng: -118.24273},
						{lat: 33.92889, lng: -118.24554},
						{lat: 33.92894, lng: -118.24705},
						{lat: 33.92894, lng: -118.24779},
						{lat: 33.92891, lng: -118.24864},
						{lat: 33.92884, lng: -118.24949},
						{lat: 33.92873, lng: -118.25045},
						{lat: 33.92833, lng: -118.25342},
						{lat: 33.92808, lng: -118.25533},
						{lat: 33.92782, lng: -118.25744},
						{lat: 33.92771, lng: -118.25874},
						{lat: 33.92767, lng: -118.25972},
						{lat: 33.92767, lng: -118.26105},
						{lat: 33.92767, lng: -118.26353},
						{lat: 33.92769, lng: -118.26596},
						{lat: 33.92769, lng: -118.26765},
						{lat: 33.92768, lng: -118.26834},
						{lat: 33.92768, lng: -118.26953},
						{lat: 33.92774, lng: -118.27046},
						{lat: 33.92781, lng: -118.27115},
						{lat: 33.92797, lng: -118.27212},
						{lat: 33.92816, lng: -118.27315},
						{lat: 33.92861, lng: -118.27537},
						{lat: 33.92867, lng: -118.27582},
						{lat: 33.92883, lng: -118.27699},
						{lat: 33.92888, lng: -118.27761},
						{lat: 33.92892, lng: -118.27829},
						{lat: 33.92893, lng: -118.27878},
						{lat: 33.92893, lng: -118.27938},
						{lat: 33.92893, lng: -118.27974},
						{lat: 33.92892, lng: -118.28012},
						{lat: 33.92892, lng: -118.28061},
						{lat: 33.92892, lng: -118.28283},
						{lat: 33.92891, lng: -118.28615},
						{lat: 33.92892, lng: -118.28712},
						{lat: 33.9289, lng: -118.28858},
						{lat: 33.92891, lng: -118.29345},
						{lat: 33.92883, lng: -118.29442},
						{lat: 33.92868, lng: -118.29558},
						{lat: 33.92854, lng: -118.29631},
						{lat: 33.92849, lng: -118.29653},
						{lat: 33.92835, lng: -118.29734},
						{lat: 33.92823, lng: -118.29786},
						{lat: 33.92787, lng: -118.29934},
						{lat: 33.92766, lng: -118.3003},
						{lat: 33.9276, lng: -118.30057},
						{lat: 33.92625, lng: -118.30663},
						{lat: 33.92611, lng: -118.30728},
						{lat: 33.9259, lng: -118.30845},
						{lat: 33.92579, lng: -118.30922},
						{lat: 33.92569, lng: -118.31013},
						{lat: 33.92562, lng: -118.31107},
						{lat: 33.92559, lng: -118.31163},
						{lat: 33.92556, lng: -118.31238},
						{lat: 33.92556, lng: -118.31343},
						{lat: 33.92557, lng: -118.31385},
						{lat: 33.92559, lng: -118.31585},
						{lat: 33.92561, lng: -118.31731},
						{lat: 33.92563, lng: -118.31966},
						{lat: 33.92565, lng: -118.32143},
						{lat: 33.92562, lng: -118.32227},
						{lat: 33.92539, lng: -118.32642},
						{lat: 33.92537, lng: -118.3266},
						{lat: 33.92523, lng: -118.32917},
						{lat: 33.92508, lng: -118.33185},
						{lat: 33.92502, lng: -118.33281},
						{lat: 33.92503, lng: -118.33369},
						{lat: 33.92508, lng: -118.3343},
						{lat: 33.92522, lng: -118.33524},
						{lat: 33.9254, lng: -118.33599},
						{lat: 33.92558, lng: -118.3366},
						{lat: 33.92564, lng: -118.33681},
						{lat: 33.92576, lng: -118.33712},
						{lat: 33.92599, lng: -118.33763},
						{lat: 33.9264, lng: -118.33843},
						{lat: 33.92684, lng: -118.33917},
						{lat: 33.92734, lng: -118.33985},
						{lat: 33.92817, lng: -118.34102},
						{lat: 33.92844, lng: -118.34123},
						{lat: 33.92871, lng: -118.34153},
						{lat: 33.92944, lng: -118.34246},
						{lat: 33.92977, lng: -118.34291},
						{lat: 33.9303, lng: -118.34374},
						{lat: 33.93058, lng: -118.34413},
						{lat: 33.93097, lng: -118.3447},
						{lat: 33.93163, lng: -118.34568},
						{lat: 33.93193, lng: -118.3462},
						{lat: 33.93233, lng: -118.34688},
						{lat: 33.93253, lng: -118.34727},
						{lat: 33.93287, lng: -118.34797},
						{lat: 33.93309, lng: -118.34846},
						{lat: 33.93366, lng: -118.34982},
						{lat: 33.93388, lng: -118.35048},
						{lat: 33.93402, lng: -118.35122},
						{lat: 33.93409, lng: -118.3519},
						{lat: 33.93413, lng: -118.35249},
						{lat: 33.93402, lng: -118.35274},
						{lat: 33.9338, lng: -118.35274},
						{lat: 33.93337, lng: -118.35273},
						{lat: 33.93267, lng: -118.35272},
						{lat: 33.93089, lng: -118.35272},
						{lat: 33.93089, lng: -118.35248},
						{lat: 33.931, lng: -118.35248},
						{lat: 33.931, lng: -118.35244},
						{lat: 33.93005, lng: -118.35244},
						{lat: 33.92799, lng: -118.35243},
						{lat: 33.92546, lng: -118.35244},
						{lat: 33.92398, lng: -118.35244},
						{lat: 33.92364, lng: -118.35244},
						{lat: 33.92291, lng: -118.3524},
						{lat: 33.92194, lng: -118.3524},
						{lat: 33.92006, lng: -118.35242},
						{lat: 33.92003, lng: -118.35242},
						{lat: 33.92003, lng: -118.35251},
						{lat: 33.92002, lng: -118.35271},
						{lat: 33.91908, lng: -118.35275},
						{lat: 33.91878, lng: -118.35275},
						{lat: 33.91798, lng: -118.35275},
						{lat: 33.91651, lng: -118.35277},
						{lat: 33.916, lng: -118.35275},
						{lat: 33.91553, lng: -118.35273},
						{lat: 33.91552, lng: -118.35278},
						{lat: 33.91545, lng: -118.35278},
						{lat: 33.9145, lng: -118.35278},
						{lat: 33.91364, lng: -118.35278},
						{lat: 33.9133, lng: -118.35278},
						{lat: 33.91324, lng: -118.35276},
						{lat: 33.9132, lng: -118.35271},
						{lat: 33.9127, lng: -118.35272},
						{lat: 33.91186, lng: -118.35277},
						{lat: 33.91143, lng: -118.35279},
						{lat: 33.91077, lng: -118.3528},
						{lat: 33.90979, lng: -118.35278},
						{lat: 33.9086, lng: -118.3528},
						{lat: 33.90692, lng: -118.35278},
						{lat: 33.90579, lng: -118.35276},
						{lat: 33.90454, lng: -118.35278},
						{lat: 33.90278, lng: -118.35278},
						{lat: 33.90033, lng: -118.35278},
						{lat: 33.89875, lng: -118.35277},
						{lat: 33.89773, lng: -118.35278},
						{lat: 33.89672, lng: -118.35277},
						{lat: 33.89638, lng: -118.35276},
						{lat: 33.89483, lng: -118.35278},
						{lat: 33.89269, lng: -118.35278},
						{lat: 33.89155, lng: -118.35276},
						{lat: 33.88969, lng: -118.35278},
						{lat: 33.88882, lng: -118.35277},
						{lat: 33.88796, lng: -118.35278},
						{lat: 33.88639, lng: -118.35276},
						{lat: 33.88548, lng: -118.35277},
						{lat: 33.88486, lng: -118.35276},
						{lat: 33.88407, lng: -118.35274},
						{lat: 33.88273, lng: -118.35276},
						{lat: 33.88095, lng: -118.35276},
						{lat: 33.88045, lng: -118.35275},
						{lat: 33.88045, lng: -118.35275},
						{lat: 33.8798, lng: -118.35274},
						{lat: 33.87854, lng: -118.35276},
						{lat: 33.87639, lng: -118.35274},
						{lat: 33.87479, lng: -118.35275},
						{lat: 33.87458, lng: -118.35274},
						{lat: 33.87424, lng: -118.35277},
						{lat: 33.87352, lng: -118.35275},
						{lat: 33.87285, lng: -118.35274},
						{lat: 33.87212, lng: -118.35274},
						{lat: 33.86893, lng: -118.35275},
						{lat: 33.86697, lng: -118.35277},
						{lat: 33.8655, lng: -118.35276},
						{lat: 33.86551, lng: -118.35254},
						{lat: 33.86551, lng: -118.35243},
						{lat: 33.86485, lng: -118.35245},
						{lat: 33.86465, lng: -118.35244},
						{lat: 33.86426, lng: -118.35245},
						{lat: 33.86278, lng: -118.35244},
						{lat: 33.86193, lng: -118.35244},
						{lat: 33.86128, lng: -118.35242},
						{lat: 33.86, lng: -118.35243},
						{lat: 33.85982, lng: -118.35244},
						{lat: 33.85952, lng: -118.35254},
						{lat: 33.85932, lng: -118.35263},
						{lat: 33.85919, lng: -118.35271},
						{lat: 33.85907, lng: -118.3528},
						{lat: 33.85874, lng: -118.35309},
						{lat: 33.85851, lng: -118.35326},
						{lat: 33.85833, lng: -118.35338},
						{lat: 33.85819, lng: -118.35344},
						{lat: 33.85803, lng: -118.3535},
						{lat: 33.85773, lng: -118.35355},
						{lat: 33.85743, lng: -118.35357},
						{lat: 33.85658, lng: -118.35358},
						{lat: 33.85613, lng: -118.35356},
						{lat: 33.85501, lng: -118.35359},
						{lat: 33.8535, lng: -118.35358},
						{lat: 33.85148, lng: -118.35359},
						{lat: 33.85025, lng: -118.35358},
						{lat: 33.84862, lng: -118.35359},
						{lat: 33.84782, lng: -118.35358},
						{lat: 33.84696, lng: -118.3536},
						{lat: 33.84562, lng: -118.3536},
						{lat: 33.84495, lng: -118.35358},
						{lat: 33.84372, lng: -118.35358},
						{lat: 33.84132, lng: -118.3536},
						{lat: 33.83879, lng: -118.35357},
						{lat: 33.83815, lng: -118.35353},
						{lat: 33.83761, lng: -118.35347},
						{lat: 33.83577, lng: -118.35314},
						{lat: 33.83449, lng: -118.35294},
						{lat: 33.83369, lng: -118.35281},
						{lat: 33.83246, lng: -118.35261},
						{lat: 33.8312, lng: -118.35239},
						{lat: 33.82962, lng: -118.35214},
						{lat: 33.82834, lng: -118.35191},
						{lat: 33.82717, lng: -118.35171},
						{lat: 33.82558, lng: -118.35132},
						{lat: 33.82542, lng: -118.35127},
						{lat: 33.8254, lng: -118.35089},
						{lat: 33.82416, lng: -118.35089},
						{lat: 33.82411, lng: -118.3509},
						{lat: 33.82406, lng: -118.35095},
						{lat: 33.82318, lng: -118.35092},
						{lat: 33.82134, lng: -118.35093},
						{lat: 33.81473, lng: -118.35096},
						{lat: 33.81321, lng: -118.35096},
						{lat: 33.81084, lng: -118.35098},
						{lat: 33.80897, lng: -118.35097},
						{lat: 33.8082, lng: -118.35099},
						{lat: 33.80655, lng: -118.351},
						{lat: 33.80511, lng: -118.35099},
						{lat: 33.8049, lng: -118.35101},
						{lat: 33.80495, lng: -118.35113},
						{lat: 33.80499, lng: -118.35111},
						{lat: 33.80471, lng: -118.35045},
						{lat: 33.80433, lng: -118.3495},
						{lat: 33.80417, lng: -118.3491},
						{lat: 33.80391, lng: -118.34854},
						{lat: 33.80347, lng: -118.34775},
						{lat: 33.80342, lng: -118.34767},
						{lat: 33.80343, lng: -118.3476},
						{lat: 33.80292, lng: -118.34691},
						{lat: 33.80232, lng: -118.34617},
						{lat: 33.80157, lng: -118.34523},
						{lat: 33.80117, lng: -118.34476},
						{lat: 33.80043, lng: -118.34385},
						{lat: 33.79796, lng: -118.34085},
						{lat: 33.7954, lng: -118.33764},
						{lat: 33.79422, lng: -118.33607},
						{lat: 33.79365, lng: -118.33527},
						{lat: 33.79326, lng: -118.33477},
						{lat: 33.79205, lng: -118.33311},
						{lat: 33.79103, lng: -118.33173},
						{lat: 33.79016, lng: -118.33052},
						{lat: 33.78992, lng: -118.33008},
						{lat: 33.78983, lng: -118.32986},
						{lat: 33.78962, lng: -118.32912},
						{lat: 33.78957, lng: -118.32881},
						{lat: 33.78955, lng: -118.32852},
						{lat: 33.78954, lng: -118.3284},
						{lat: 33.78954, lng: -118.32803},
						{lat: 33.78954, lng: -118.3269},
						{lat: 33.78954, lng: -118.32542},
						{lat: 33.78954, lng: -118.32392},
						{lat: 33.78953, lng: -118.32081},
						{lat: 33.78952, lng: -118.31654},
						{lat: 33.78949, lng: -118.31282},
						{lat: 33.78948, lng: -118.30997},
						{lat: 33.78947, lng: -118.30771},
						{lat: 33.78942, lng: -118.30771},
						{lat: 33.78942, lng: -118.30771},
						{lat: 33.78839, lng: -118.3077},
						{lat: 33.7876, lng: -118.30773},
						{lat: 33.78723, lng: -118.30782},
						{lat: 33.78687, lng: -118.30797},
						{lat: 33.78643, lng: -118.30827},
						{lat: 33.78605, lng: -118.30864},
						{lat: 33.78577, lng: -118.30896},
						{lat: 33.78561, lng: -118.30912},
						{lat: 33.78535, lng: -118.30937},
						{lat: 33.78513, lng: -118.30954},
						{lat: 33.7849, lng: -118.30969},
						{lat: 33.78471, lng: -118.30978},
						{lat: 33.78439, lng: -118.30991},
						{lat: 33.78407, lng: -118.30998},
						{lat: 33.7838, lng: -118.31001},
						{lat: 33.78334, lng: -118.31001},
						{lat: 33.78277, lng: -118.31001},
						{lat: 33.78247, lng: -118.31001},
						{lat: 33.78215, lng: -118.31001},
						{lat: 33.78174, lng: -118.30997},
						{lat: 33.77997, lng: -118.30972},
						{lat: 33.77579, lng: -118.30913},
						{lat: 33.77411, lng: -118.30889},
						{lat: 33.77372, lng: -118.30885},
						{lat: 33.77315, lng: -118.30886},
						{lat: 33.77262, lng: -118.30893},
						{lat: 33.77203, lng: -118.30908},
						{lat: 33.77026, lng: -118.30959},
						{lat: 33.76723, lng: -118.31048},
						{lat: 33.76322, lng: -118.31164},
						{lat: 33.76284, lng: -118.31176},
						{lat: 33.76129, lng: -118.31221},
						{lat: 33.76015, lng: -118.31254},
						{lat: 33.75978, lng: -118.31263},
						{lat: 33.75953, lng: -118.31265},
						{lat: 33.75924, lng: -118.31264},
						{lat: 33.75888, lng: -118.31255},
						{lat: 33.7586, lng: -118.31245},
						{lat: 33.75839, lng: -118.31233},
						{lat: 33.75818, lng: -118.3122},
						{lat: 33.75796, lng: -118.31201},
						{lat: 33.75776, lng: -118.3118},
						{lat: 33.75748, lng: -118.31144},
						{lat: 33.75715, lng: -118.31104},
						{lat: 33.75686, lng: -118.31073},
						{lat: 33.75656, lng: -118.31046},
						{lat: 33.75613, lng: -118.31014},
						{lat: 33.75555, lng: -118.30979},
						{lat: 33.75531, lng: -118.30967},
						{lat: 33.75493, lng: -118.30952},
						{lat: 33.75427, lng: -118.30925},
						{lat: 33.75383, lng: -118.30911},
						{lat: 33.75362, lng: -118.30907},
						{lat: 33.7529, lng: -118.30898},
						{lat: 33.75256, lng: -118.30898},
						{lat: 33.75226, lng: -118.309},
						{lat: 33.75186, lng: -118.30906},
						{lat: 33.75158, lng: -118.30908},
						{lat: 33.75106, lng: -118.30916},
						{lat: 33.75076, lng: -118.30919},
						{lat: 33.74962, lng: -118.30935},
						{lat: 33.74822, lng: -118.30954},
						{lat: 33.74781, lng: -118.30958},
						{lat: 33.74711, lng: -118.30968},
						{lat: 33.74659, lng: -118.30971},
						{lat: 33.74614, lng: -118.30971},
						{lat: 33.74607, lng: -118.30964},
						{lat: 33.74571, lng: -118.30964},
						{lat: 33.74517, lng: -118.30964},
						{lat: 33.74512, lng: -118.30971},
						{lat: 33.74511, lng: -118.30972},
						{lat: 33.74417, lng: -118.30973},
						{lat: 33.74387, lng: -118.30974},
						{lat: 33.74358, lng: -118.30979},
						{lat: 33.74298, lng: -118.30998},
						{lat: 33.74127, lng: -118.31058},
						{lat: 33.74048, lng: -118.31087},
						{lat: 33.74024, lng: -118.311},
						{lat: 33.74002, lng: -118.31116},
						{lat: 33.73966, lng: -118.3115},
						{lat: 33.7394, lng: -118.31183},
						{lat: 33.73915, lng: -118.31229},
						{lat: 33.73902, lng: -118.31262},
						{lat: 33.73891, lng: -118.31301},
						{lat: 33.73876, lng: -118.31374},
						{lat: 33.73864, lng: -118.31435},
						{lat: 33.73856, lng: -118.31465},
						{lat: 33.73847, lng: -118.31492},
						{lat: 33.73837, lng: -118.31516},
						{lat: 33.73822, lng: -118.31543},
						{lat: 33.73797, lng: -118.31578},
						{lat: 33.73778, lng: -118.31599},
						{lat: 33.73756, lng: -118.31617},
						{lat: 33.73735, lng: -118.31632},
						{lat: 33.7371, lng: -118.31644},
						{lat: 33.73686, lng: -118.31653},
						{lat: 33.73667, lng: -118.31658},
						{lat: 33.73623, lng: -118.31663},
						{lat: 33.73516, lng: -118.31674},
						{lat: 33.73453, lng: -118.31679},
						{lat: 33.73414, lng: -118.31676},
						{lat: 33.73381, lng: -118.31668},
						{lat: 33.73363, lng: -118.31661},
						{lat: 33.73323, lng: -118.3164},
						{lat: 33.73289, lng: -118.31614},
						{lat: 33.73188, lng: -118.31529},
						{lat: 33.73102, lng: -118.31456},
						{lat: 33.73044, lng: -118.31407},
						{lat: 33.73024, lng: -118.31389},
						{lat: 33.73005, lng: -118.31369},
						{lat: 33.72971, lng: -118.3132},
						{lat: 33.72951, lng: -118.3129},
						{lat: 33.72913, lng: -118.31242},
						{lat: 33.72885, lng: -118.31214},
						{lat: 33.72854, lng: -118.31191},
						{lat: 33.72836, lng: -118.31181},
						{lat: 33.72806, lng: -118.31168},
						{lat: 33.72707, lng: -118.31131},
						{lat: 33.72696, lng: -118.31123},
						{lat: 33.72691, lng: -118.31123},
						{lat: 33.72679, lng: -118.31121},
						{lat: 33.72654, lng: -118.31121},
						{lat: 33.72602, lng: -118.31127},
						{lat: 33.72566, lng: -118.31132},
						{lat: 33.72518, lng: -118.31134},
						{lat: 33.72488, lng: -118.31137},
						{lat: 33.72467, lng: -118.31142},
						{lat: 33.72431, lng: -118.31157},
						{lat: 33.72407, lng: -118.31173},
						{lat: 33.72394, lng: -118.31184},
						{lat: 33.72365, lng: -118.31209},
						{lat: 33.72341, lng: -118.31231},
						{lat: 33.72319, lng: -118.31259},
						{lat: 33.72287, lng: -118.31303},
						{lat: 33.72194, lng: -118.31429},
						{lat: 33.72179, lng: -118.31452},
						{lat: 33.72173, lng: -118.31467},
						{lat: 33.72171, lng: -118.31479},
						{lat: 33.72168, lng: -118.31541},
						{lat: 33.72164, lng: -118.31572},
						{lat: 33.72155, lng: -118.31591},
						{lat: 33.72102, lng: -118.31679},
						{lat: 33.72073, lng: -118.31721},
						{lat: 33.72037, lng: -118.31765},
						{lat: 33.72007, lng: -118.31814},
						{lat: 33.71967, lng: -118.31912},
						{lat: 33.71953, lng: -118.3194},
						{lat: 33.71906, lng: -118.31997},
						{lat: 33.71905, lng: -118.31999},
						{lat: 33.71905, lng: -118.31999},
						{lat: 33.7174893331, lng: -118.321092669},
						{lat: 33.7174893331, lng: -118.321092669},
						{lat: 33.6328384388, lng: -118.319263402},
						{lat: 33.6332086653, lng: -117.966498929},
					];

					const bounds = new google.maps.LatLngBounds();
					innerCoordinates.forEach(coordinate => {
						bounds.extend(coordinate);
					});
					const center = bounds.getCenter();

					const boxMapHeight = boxMap.getBoundingClientRect().height;
					let zoom = 9;
					if (boxMapHeight >= 684) {
						zoom = 11;
					} else if (boxMapHeight >= 394) {
						zoom = 10;
					}

					map = new google.maps.Map(boxMap, {
						center: {lat: center.lat(), lng: center.lng()},
						mapTypeControlOptions: {
							style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
						},
						minZoom: 8,
						styles: isDarkMode ? darkModeStyles : [],
						zoom
					});

					map.data.add({
						geometry: new google.maps.Data.Polygon([outerCoordinates, innerCoordinates])
					});
					map.data.setStyle({
						fillColor: '#dc3232',
						strokeColor: '#dc3232',
						strokeWeight: 1
					});
				};

				const scriptSrc = `https://maps.googleapis.com/maps/api/js?callback=Function.prototype&key=${this.googleMapsKey}&libraries=places&loading=async`;
				const existingScript = document.querySelector(`script[src='${scriptSrc}']`);

				// Check if thet script’s already been loaded
				if (existingScript) {
					// If it has and window.google is defined…
					if (window.google) {
						// …immediately call the handler
						onloadHandler();
					} else {
						// …wait for the script to load and call the original handler too
						const originalOnload = existingScript.onload;
						existingScript.onload = function() {
							originalOnload.apply(this, arguments);
							onloadHandler();
						};
					}
				} else {
					const mapsScript = document.createElement('script');
					mapsScript.async = true;
					mapsScript.onload = onloadHandler;
					mapsScript.src = scriptSrc;
					mapsScript.type = 'text/javascript';
					document.getElementsByTagName('head')[0].appendChild(mapsScript);
				}
			}
		};

		// Start listening for changes to dark mode
		mediaQueryList.addListener(mediaQueryListener);

		// Immediately call the listener to initially set up the map
		mediaQueryListener();

		return () => {
			// Remove the dark mode media query listener
			mediaQueryList.removeListener(mediaQueryListener);
		};
	}
});

export default Component.extend({
	tag: 'lbh3-hareline-guidelines',
	ViewModel,
	view
});
